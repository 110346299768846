let key = {};
if (process.env.REACT_APP_MODE === "production") {
  // const API_URL = "https://nilwireapi.wealwin.com"; //Demo
  const API_URL = "https://api.satxswap.com"; //https://api.nilwirenft.io
  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0xc0eCb515891f21628F8e4B7696241B7A59A3CEE7",
    multiplesmartContract: "0x28D9E10E464ed5AB1F4fb28e1B2B909bC928896d",
    network: 1
  };
} else {
  //Demo
  const API_URL = "http://localhost:2053";
  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0x93310d33c44e5BE5bF208B89cA00096613f887cF",
    multiplesmartContract: "0x5D500Ee1a10AF9310560F75a66Cf885C96089A43",
    network: 5
  };
}

export default key;
